export default {
  publishedTab: 'Published ({{count}})',
  othersTab: 'Not published ({{count}})',
  publishSettingsBtn: 'Publishing settings',
  reporting: {
    gmvTab: 'GMV',
    countTab: 'Count',
    timezoneMessage: 'Policy hits are displayed in UTC time zone',
    timezoneTooltip:
      'Policy hits are displayed in UTC time zone only. This will change some of the hit’s reported dates for merchants outside of UTC time zone.',
    gmvHeader: 'Hits by GMV',
    countHeader: 'Hits by count',
    values: {
      countHits: 'Hits: Count',
      countHitsTooltip: {
        CHECKOUT:
          'Hits by count is the number of orders impacted by the policy over the selected time frame.',
        RETURN:
          'Hits by count is the number of returns impacted by the policy over the selected time frame.',
      },
      gmvHits: 'Hits: GMV',
      gmvHitsTooltip: {
        CHECKOUT:
          'Hits by GMV is the aggregate order amount impacted by all policies over the selected time frame.',
        RETURN:
          'Hits by GMV is the aggregate returns amount impacted by all policies over the selected time frame.',
      },
      countPercentageOfTotal: {
        CHECKOUT: '% of total order count',
        RETURN: '% of total returns',
      },
      countPercentageOfTotalTooltip: {
        CHECKOUT:
          'This percentage is calculated by dividing the policy’s hits by count over all order traffic. ',
        RETURN:
          'This percentage is calculated by dividing the policy’s hits by count over all returns traffic. ',
      },
      gmvPercentageOfTotal: {
        CHECKOUT: '% of total orders GMV',
        RETURN: '% of total returns GMV',
      },
      gmvPercentageOfTotalTooltip: {
        CHECKOUT:
          'This percentage is calculated by dividing all policy’s GMV over all orders GMV.',
        RETURN:
          'This percentage is calculated by dividing all policy’s GMV over all returns GMV.',
      },

      Accept: 'Accept',
      Reject: 'Reject',
      Hold: 'Hold',
      Credit: 'Credit',
    },
    chart: {
      tooltip_Accept: 'Accept: {{value}}',
      tooltip_Reject: 'Reject: {{value}}',
      tooltip_Hold: 'Hold: {{value}}',
      tooltip_Credit: 'Credit: {{value}}',
    },
  },
  ruleSuccessModal: {
    createPolicy: {
      title: 'Success! Policy created.',
      ruleListLabel: 'NEW POLICY',
    },
    editPolicy: {
      title: 'Policy change was saved!',
      ruleListLabel: 'EDITED POLICY',
    },
    cancelText: 'View all policies',
    createNewRule: 'Create another policy',
    publishingSettings: 'Publishing settings',
  },
  ruleSuccessModalHelp: {
    title: 'Not sure what to do?',
    description1:
      '<bold>New</bold> policies are not yet impacting order traffic until published. Publish policy now or create more policies to publish together later.',
    description2:
      'Not ready to publish? Choose <bold>View all policies</bold> and find new policies in the <bold>Not to publish</bold> section.',
  },
  ruleDropdown: {
    viewSummary: 'View performance & summary',
    duplicate: 'Duplicate',
    editRuleDetails: 'Edit details',
    deletePolicy: 'Delete policy',
    editSchedule: 'Edit schedule',
    addSchedule: 'Add schedule',
    publishingDivider: 'PUBLISHING SETTINGS',
    managePolicyDivider: 'MANAGE POLICY',
    moveToOthers: 'Move to <bold>Not published</bold>',
    moveToPublished: 'Move to <bold>Published</bold>',
    changeRank: 'Change rank',
  },
  ruleTable: {
    ruleDetailsModal: {
      title: 'Edit details',
      okText: 'Apply',
    },
    ruleScheduleModal: {
      addSchedule: 'Add schedule',
      manageSchedule: 'Manage schedule',
    },
    columnTitles: {
      translatedCheckpoints: {
        order_one: 'order',
        order_other: 'orders',
        returns: 'returns',
      },
      rank: 'Rank',
      name: 'Policy',
      action: 'Action',
      hitsByCount: 'Hits by count',
      totalPercentage: '% of total {{translatedCheckpointItem}} count',
      affectedPercentage:
        '% of total {{translatedCheckpointItem}} affected by policies count',
      hitsByGMV: 'Hits by GMV',
      totalGMV: '% of total {{translatedCheckpointItem}} GMV',
      affectedGMVPercentage:
        '% of total {{translatedCheckpointItem}} GMV affected by policies',
      schedule: 'Schedule',
      totalHits: 'Total hits to date',
      status: 'Status',
    },
    tooltips: {
      hitsByCount:
        'Hits by count is the number of {{translatedCheckpointItem}} impacted by the policy over the selected time frame.',
      totalPercentage:
        "The percentage is calculated by dividing the policy's hits by count over all {{translatedCheckpointItem}} traffic.",
      affectedPercentage:
        'This percentage is calculated by dividing the policy’s hits by count over all {{translatedCheckpointItem}} hits by count.',
      hitsByGMV:
        'Hits by GMV is the aggregate {{translatedCheckpointItem}} amount impacted by the policy over the selected time frame.',
      totalGMV:
        'This percentage is calculated by dividing the policy’s hits by GMV over all {{translatedCheckpointItem}} GMV.',
      affectedGMVPercentage:
        'This percentage is calculated by dividing the policy’s hits by GMV over all policies hits by GMV.',
      totalHits:
        'Total number of hits by count since the policy was first published.',
    },
    errorText:
      'Apologies - our system encountered an error loading data. Try again.',
    emptyText: {
      published: 'No published policies',
      others: 'No other policies exist',
    },
    filters: {
      sortByLabel: 'Sort by',
      options: {
        rank: 'Rank',
        hits: 'Hits',
        createdDate: 'Created date',
        startDate: 'Start date',
        endDate: 'End date',
      },
    },
  },
  publishedRuleSetInfo: {
    lastPublished: 'Last published',
  },
  policyCheckpointForm: {
    main: {
      header: "Let's start with the customer's journey",
      description: 'Select when you want this policy to take effect',
    },
    actionButtonTitles: {
      checkout: 'Checkout',
      login: 'Login',
      return: 'Return',
    },
  },
  pilotBanner: {
    pilotInProgress_one:
      'A pilot policy is running in the background. {{count}} more day before completion.',
    pilotInProgress_other:
      'A pilot policy is running in the background. {{count}} more days before completion.',
    pilotInProgressHours_one:
      'A pilot policy is running in the background. {{count}} more hour before completion.',
    pilotInProgressHours_other:
      'A pilot policy is running in the background. {{count}} more hours before completion.',
    isAfterEndDate:
      'A pilot policy is still running. Please check again later or wait until you receive the pilot completion email.',
    pilotCompleted: 'Pilot policy has successfully completed.',
    viewActivePilot: 'View active pilot',
    viewPilotResults: 'View pilot results',
  },
}
