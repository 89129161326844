import { FC, useState } from 'react'
import { Divider, Row, Col, Flex, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  ChevronDown,
  ChevronRight,
  Space,
  TextThirdGen,
} from '@signifyd/components'
import { PREDICTION_LIST_TYPE, PredictionList } from '@signifyd/http'
import { arrayOneOrMore } from '@signifyd/utils'
import Collapse from 'core/components/Collapse'
import FlippedStatistic from 'core/components/FlippedStatistic'
import ListRuleDropdown from 'pages/ListDetailsPage/containers/ListRuleDropdown'
import { spacingXS } from '@signifyd/ant'
import FormatDate from 'core/components/FormatDate'
import { colorGlitter, colorMarengo } from '@signifyd/colors'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import styles from './ListDetailsPageSidePanelThirdGen.less'
import ListExpirations from './ListExpirations'
import { getExpirations } from './listExpirationUtils'
import ListLastEventThirdGen from '../ListLastEvent/ListLastEventThirdGen'

const { Panel } = Collapse

interface Props {
  predictionList: PredictionList
}

const ListDetailsPageSidePanelThirdGen: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'listDetailsPage.sidePanel',
  })
  const [showExpiration, setShowExpiration] = useState<boolean>(false)
  const userTimeZone = useUserTimeZone()

  const { expiration, type } = predictionList
  const expirationCount = getExpirations(expiration).length

  const renderExpiration = ![
    PREDICTION_LIST_TYPE.PROMO_ABUSE,
    PREDICTION_LIST_TYPE.RESELLER_ABUSE,
  ].includes(type)

  return (
    <Flex vertical className={styles.panel}>
      <Row gutter={6} className={styles.container}>
        <Col span={12}>
          <FlippedStatistic
            title={t('listSize')}
            value={predictionList.size}
            data-test-id="listSize"
          />
        </Col>
        <Col span={12}>
          <FlippedStatistic
            title={t('numRulesInList')}
            value={predictionList.ruleIds.length}
            suffix={
              arrayOneOrMore(predictionList.ruleIds) ? (
                <ListRuleDropdown ruleIds={predictionList.ruleIds} />
              ) : undefined
            }
            data-test-id="numRulesInList"
          />
        </Col>
      </Row>
      <div>
        <Divider />
        {renderExpiration && (
          <>
            <Collapse
              defaultActiveKey={[]}
              className={styles.collapse}
              activeKey={showExpiration ? ['2'] : []}
              onChange={() => setShowExpiration(!showExpiration)}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <ChevronDown fill={colorMarengo} />
                ) : (
                  <ChevronRight fill={colorMarengo} />
                )
              }
            >
              <Panel
                header={
                  <Flex justify="space-between" align="center">
                    <TextThirdGen
                      weight="semibold"
                      data-test-id="expirationTitle"
                    >
                      {t('expirationConfiguration')}
                    </TextThirdGen>
                    <Tag color={colorGlitter} className={styles.tagText}>
                      {expirationCount}
                    </Tag>
                  </Flex>
                }
                key="2"
                theme="transparent"
              >
                <div className={styles.expirations}>
                  <ListExpirations predictionList={predictionList} />
                </div>
              </Panel>
            </Collapse>
            <Divider />
          </>
        )}

        <Space size="lg" />
        <Flex vertical gap={spacingXS} className={styles.container}>
          <TextThirdGen weight="semibold" type="secondary">
            {t('lastActivity')}
          </TextThirdGen>
          {predictionList.lastEvent && (
            <ListLastEventThirdGen
              updatedAt={predictionList.updatedAt}
              listType={predictionList.type}
              updatedByName={predictionList.updatedByName}
              lastEvent={predictionList.lastEvent}
            />
          )}
        </Flex>
        <Space size="md" />
        <Flex vertical gap={spacingXS} className={styles.container}>
          <TextThirdGen weight="semibold" type="secondary">
            {t('createdBy')}
          </TextThirdGen>
          <TextThirdGen size="sm" type="secondary">
            {t('createdByText', { name: predictionList.createdByName })}
            <FormatDate
              UTCTimestamp={predictionList.createdAt}
              timeZone={userTimeZone}
            />
            <Divider type="vertical" />
            {userTimeZone}
          </TextThirdGen>
        </Flex>
      </div>
      <Space size="xxl" />
    </Flex>
  )
}

export default ListDetailsPageSidePanelThirdGen
