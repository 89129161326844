import { FC } from 'react'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Card, Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { P, TextThirdGen, TitleThirdGen } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import FormatDate from 'core/components/FormatDate'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import PredictionListTypeTagThirdGen from 'core/containers/PredictionListTypeTag/PredictionListTypeTagThirdGen'
import { spacingSM, spacingXS } from '@signifyd/ant'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import styles from './PredictionListCardThirdGen.less'

interface Props {
  predictionList: PredictionList
}

const PredictionListCardThirdGen: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'listPage.listCard' })
  const navigate = useNavigate()
  const userTimeZone = useUserTimeZone()

  const [query] = useDashboardPageFilters()
  const currentTeamId = query.teamId

  const itemText = `${predictionList.size} ${t('item', {
    count: predictionList.size,
  })}`
  const ruleText = `${predictionList.ruleIds.length} ${t('rule', {
    count: predictionList.ruleIds.length,
  })}`

  const expirationListCounter = Object.values(predictionList.expiration).filter(
    (value) => value !== null
  ).length

  const expirationListText = t('expirationText', {
    count: expirationListCounter,
  })

  const hasExpirationList = expirationListCounter > 0

  const navigationUrl = `/lists/${predictionList.id}?teamId=${currentTeamId}`

  return (
    <Card
      data-datadog-id="prediction-list-card"
      data-test-id="predictionListCard"
      hoverable
      onClick={() => {
        navigate(navigationUrl, {
          state: { backToPreviousPage: true },
        })
      }}
    >
      <Flex vertical className={styles.wrapper} justify="space-between">
        <>
          <Flex justify="space-between" wrap gap={spacingXS}>
            <PredictionListTypeTagThirdGen type={predictionList.type} />
            <TextThirdGen className={styles.subText}>
              <FormatDate
                UTCTimestamp={predictionList.updatedAt}
                format="M/D/YYYY"
                timeZone={userTimeZone}
              />
            </TextThirdGen>
          </Flex>
          <P ellipsis={{ rows: 2 }}>
            <TitleThirdGen level={2} className={styles.listName}>
              {predictionList.name}
            </TitleThirdGen>
          </P>
          <P ellipsis={{ rows: 3 }} className={styles.subText}>
            <TextThirdGen>{predictionList.description}</TextThirdGen>
          </P>
        </>
        <Flex align="center" justify="space-between" wrap>
          <TextThirdGen size="sm" className={styles.subText}>
            {itemText}
          </TextThirdGen>
          <TextThirdGen size="sm" className={styles.subText}>
            {ruleText}
          </TextThirdGen>
        </Flex>
      </Flex>

      {hasExpirationList && (
        <Flex
          align="center"
          justify="center"
          className={styles.expirationBanner}
          gap={spacingSM}
        >
          <ClockCircleOutlined className={styles.expirationIcon} />
          <TextThirdGen size="sm" className={styles.subText}>
            {expirationListText}
          </TextThirdGen>
        </Flex>
      )}
    </Card>
  )
}

export default PredictionListCardThirdGen
