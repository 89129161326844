import { FC, useState } from 'react'
import { Button, Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { CartIcon, Space, Text } from '@signifyd/components'
import {
  RuleSimulation,
  SIMULATION_STATUS,
  SIMULATION_TYPE,
} from '@signifyd/http'
import { PilotPreferenceModal } from 'pages/PublishWithSimulatorPage/components/PilotPreferenceModal/PilotPreferenceModal'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { useStoreState } from 'stores'
import styles from './CreateSimulationContainer.less'

interface Props {
  createPilot: (endDate: string, emailAddresses: Array<string>) => void
  pilotSimulation?: RuleSimulation
}

export const ConfigurePilot: FC<Props> = ({ createPilot, pilotSimulation }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage.pilotSimulation',
  })

  const [query, setQuery] = useQueryParams(PublishPageQueryParams)
  const [isModalOpen, setModalOpen] = useState(false)

  const isLoading = useStoreState(
    (state) => state.simulationModel.isLoading.createRuleSimulation
  )

  const handleClickViewPilot = (): void => {
    if (!pilotSimulation) {
      return
    }

    setQuery({
      ...query,
      simulationType: SIMULATION_TYPE.PILOT_POLICY,
      simulationId: pilotSimulation.simulationId,
    })
  }

  return (
    <>
      <div
        className={styles.simulationContainer}
        data-test-id="pilotSimulationContainer"
      >
        <CartIcon className={styles.icon} />
        <Space size="lg" />
        <Text size="lg" weight="semibold">
          {t('title')}
        </Text>
        <Space size="sm" />
        <div>
          <Text className={styles.description}>{t(`description`)}</Text>
        </div>
        <Space size="sm" />
        <div className={styles.description}>
          <Text>{t(`pilotRunLength`)}</Text>
        </div>
        <Space size="lg" />
        <Button
          className={styles.button}
          type="primary"
          disabled={pilotSimulation?.status === SIMULATION_STATUS.RUNNING}
          onClick={() => setModalOpen(true)}
          data-test-id="configurePilotButton"
          data-analytics-id="configure-pilot-btn"
          loading={isLoading}
        >
          {t('createPilot')}
        </Button>
        <Space size={32} />
        {pilotSimulation?.status === SIMULATION_STATUS.RUNNING && (
          <Alert
            data-test-id="existingPilotAlert"
            icon={null}
            type="info"
            message={
              <div className={styles.alert}>
                <Text>{t('existingPilot')}</Text>
                <Button
                  data-analytics-id="view-pilot-alert-btn"
                  data-test-id="viewPilotButton"
                  className={styles.viewPilotButton}
                  type="link"
                  onClick={handleClickViewPilot}
                >
                  {t('viewActivePilot')}
                </Button>
              </div>
            }
          />
        )}
      </div>
      <PilotPreferenceModal
        showModal={isModalOpen}
        onOk={(endDate, emailAddresses) => {
          setModalOpen(false)
          createPilot(endDate, emailAddresses)
          setQuery({
            ...query,
            simulationType: SIMULATION_TYPE.PILOT_POLICY,
            editPilot: true,
          })
        }}
        onCancel={() => setModalOpen(false)}
      />
    </>
  )
}
