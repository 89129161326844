import { FC, useState } from 'react'
import { Button, Flex } from 'antd'
import { Pencil, TextThirdGen, TitleThirdGen } from '@signifyd/components'
import { colorCerulean } from '@signifyd/colors'
import { PredictionList, UpdatePredictionListPayload } from '@signifyd/http'
import EditListDetailsModal from 'pages/ListDetailsPage/containers/EditListDetailsModal'
import { HTTPStatus } from 'core/http'
import useUpdatePredictionList from 'core/hooks/useUpdatePredictionList'
import { spacingXL } from '@signifyd/ant'
import styles from './ListDetailsPageTitleThirdGen.less'

interface Props {
  predictionList: PredictionList
  userCanEdit?: boolean
}

const ListDetailsPageTitleThirdGen: FC<Props> = ({
  predictionList,
  userCanEdit,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const {
    mutateAsync: updatePredictionList,
    isLoading,
    isSuccess,
    error,
  } = useUpdatePredictionList()

  const updatePredictionListHTTPStatus: HTTPStatus = {
    pending: isLoading,
    success: isSuccess,
    error: error?.message,
  }

  const updateList = async (
    listDetails: UpdatePredictionListPayload
  ): Promise<void> => {
    await updatePredictionList({
      listId: predictionList.id,
      payload: listDetails,
    })

    setModalVisible(false)
  }

  return (
    <Flex vertical>
      <Flex gap={spacingXL} align="center">
        <TitleThirdGen>{predictionList.name}</TitleThirdGen>
        {userCanEdit && (
          <Button
            type="link"
            icon={<Pencil className={styles.icon} fill={colorCerulean} />}
            onClick={() => setModalVisible(true)}
            className={styles.editButton}
          />
        )}
      </Flex>
      {predictionList?.description && (
        <TextThirdGen weight="normal" size="sm" className={styles.description}>
          {predictionList.description}
        </TextThirdGen>
      )}
      <EditListDetailsModal
        httpStatus={updatePredictionListHTTPStatus}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={async (listDetails) => updateList(listDetails)}
        currentName={predictionList.name}
        currentDescription={predictionList.description || undefined}
        usedInPolicy={predictionList.ruleIds.length > 0}
      />
    </Flex>
  )
}

export default ListDetailsPageTitleThirdGen
