import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Statistic, Divider, Row, Col, Tooltip, Flex } from 'antd'
import { Text, Space, TextThirdGen, useIsThirdGen } from '@signifyd/components'
import styles from './TotalImpact.less'

interface Props {
  isPilot: boolean
  title: ReactNode
  value: string
  percentage: string
  originalNumber: string
}

const TotalImpactStatistic: FC<Props> = ({
  isPilot,
  title,
  value,
  percentage,
  originalNumber,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage.totalImpactCard',
  })
  const isThirdGen = useIsThirdGen()

  return (
    <div data-test-id="totalImpactStatisticWrapper">
      <Statistic
        title={
          <div className={styles.statisticTitle}>
            {isThirdGen ? (
              <TextThirdGen size="sm">{title}</TextThirdGen>
            ) : (
              <Text size="sm">{title}</Text>
            )}
            <Space size={16} />
          </div>
        }
        value={value}
        valueStyle={{ fontSize: '24px', margin: '0px', padding: '0px' }} // Value can only be string | number so this is necessary
      />
      <Space size="xs" />
      <Row>
        <Flex gap={8}>
          <Col>
            <Tooltip placement="top" title={t('tooltip')}>
              <Text className={styles.percentage}>{percentage}</Text>
            </Tooltip>
          </Col>
          <Col>
            <Divider className={styles.divider} type="vertical" />
          </Col>
          <Col>
            <Text type="secondary">
              {isPilot
                ? t('numberLivePilot', {
                    formattedNumber: originalNumber,
                  })
                : t('numberBeforeSimulation', {
                    formattedNumber: originalNumber,
                  })}
            </Text>
          </Col>
        </Flex>
      </Row>
    </div>
  )
}

export default TotalImpactStatistic
