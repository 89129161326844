import { ReactNode, ReactElement } from 'react'
import { Tag } from 'antd'
import styles from './PilotPreferenceModal.less'

// This interface isn't exported by antd
interface CustomTagProps {
  label: ReactNode
  value: string
  closable: boolean
  onClose: () => void
}

export const renderEmailTag = (
  props: CustomTagProps,
  username: string
): ReactElement => {
  const { value, onClose } = props

  return (
    <Tag
      data-test-id={`emailTag-${value}`}
      className={styles.tag}
      closable={value !== username}
      onClose={onClose}
    >
      {value}
    </Tag>
  )
}
