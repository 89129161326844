import { FC, useMemo, useState } from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CHECKPOINT, RuleResponse } from '@signifyd/http'
import DeletePolicyModal from 'core/components/DeletePolicyModal/DeletePolicyModal'
import RuleScheduleModal from 'core/components/RuleScheduleModal'
import { PolicyDetails } from 'pages/DashboardPage/components/PolicyDetailsForm/PolicyDetailsForm'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'
import { useUpdatePolicyInDashboard } from 'pages/DashboardPage/queries/useUpdatePolicyInDashboard'
import { useDeletePolicyInDashboard } from 'pages/DashboardPage/queries/useDeletePolicyInDashboard'
import { isDeletedPolicy } from 'core/constants'
import ErrorComponent from 'core/components/ErrorComponent/ErrorComponent'
import { TIMEFRAME } from 'core/components/PolicyReporting/PolicyReporting.utils'
import { useIsThirdGen } from '@signifyd/components'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import EditPolicyModal from '../EditPolicyModal'
import getColumns from './PolicyTable.config'
import styles from './PolicyTable.less'
import {
  EnhancedPolicies,
  EnhancedPolicy,
} from '../DashboardContainer/queries/useGetDashboardContainerData'

interface Props {
  policyGroupType: POLICY_GROUP_TYPE
  policies: EnhancedPolicies
  isLoading: boolean
  isError: boolean
  timeframe: TIMEFRAME
  currentCheckpoint: CHECKPOINT
}

const PolicyTable: FC<Props> = ({
  policyGroupType,
  policies,
  isLoading,
  isError,
  timeframe,
  currentCheckpoint,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isThirdGen = useIsThirdGen()

  const [selectedRule, setSelectedRule] = useState<RuleResponse | null>(null)
  const [ruleDetailsModalVisible, setRuleDetailsModalVisible] = useState(false)
  const [ruleScheduleModalVisible, setRuleScheduleModalVisible] =
    useState(false)
  const [ruleDeletePolicyModalVisible, setRuleDeletePolicyModalVisible] =
    useState(false)

  const userTimeZone = useUserTimeZone()

  const visibleRules =
    policyGroupType === POLICY_GROUP_TYPE.published
      ? policies?.publishedPolicies
      : policies?.otherPolicies

  const tableLocale = useMemo(() => {
    if (isError) {
      return {
        emptyText: (
          <ErrorComponent text={t('ruleDashboardPage.ruleTable.errorText')} />
        ),
      }
    }

    return {
      emptyText:
        policyGroupType === POLICY_GROUP_TYPE.published
          ? t('ruleDashboardPage.ruleTable.emptyText.published')
          : t('ruleDashboardPage.ruleTable.emptyText.others'),
    }
  }, [t, policyGroupType, isError])

  const columns = useMemo(
    () =>
      getColumns({
        checkpoint: currentCheckpoint,
        policyGroupType,
        onClickEditRuleDetails: (rule) => {
          setSelectedRule(rule)
          setRuleDetailsModalVisible(true)
        },
        onClickDeletePolicyDetails: (rule) => {
          setRuleDeletePolicyModalVisible(true)
          setSelectedRule(rule)
        },
        onClickManageSchedule: (rule) => {
          setSelectedRule(rule)
          setRuleScheduleModalVisible(true)
        },
        styles,
        isThirdGen,
      }),
    [currentCheckpoint, isThirdGen, policyGroupType]
  )

  const updatePolicy = useUpdatePolicyInDashboard()
  const deletePolicy = useDeletePolicyInDashboard()

  const onSubmit = async ({
    name,
    description,
  }: PolicyDetails): Promise<void> => {
    if (!selectedRule) {
      return
    }

    await updatePolicy.mutateAsync({
      name,
      description,
      ruleId: selectedRule.ruleId,
    })

    setSelectedRule(null)
    setRuleDetailsModalVisible(false)
  }

  if (!visibleRules) {
    return null
  }

  const ruleScheduleModalTitle =
    selectedRule?.scheduleStart || selectedRule?.scheduleEnd
      ? t('ruleDashboardPage.ruleTable.ruleScheduleModal.manageSchedule')
      : t('ruleDashboardPage.ruleTable.ruleScheduleModal.addSchedule')

  const handleOnRowClick = (policy: EnhancedPolicy): void => {
    if (!isDeletedPolicy(policy)) {
      navigate(
        {
          pathname: `/policies/summary/${policy.ruleId}`,
          search: `teamId=${policy.teamId}&timeframe=${timeframe}`,
        },
        {
          state: { backToPreviousPage: true },
        }
      )
    }
  }

  return (
    <>
      <Table
        locale={tableLocale}
        loading={isLoading}
        size={!isThirdGen ? 'middle' : undefined}
        pagination={{
          defaultPageSize: 200,
          pageSizeOptions: [25, 50, 100, 200],
          total: visibleRules.length,
        }}
        dataSource={visibleRules}
        rowKey="ruleId"
        columns={columns}
        data-test-id={isLoading ? 'policyTableLoading' : undefined}
        rowClassName={(policy) => (!isDeletedPolicy(policy) ? styles.row : '')}
        tableLayout="fixed"
        scroll={{ x: 'scroll' }}
        onRow={(policy, i) => ({
          'data-test-id': `policyTableRow-${i}`,
          onClick: () => handleOnRowClick(policy),
        })}
      />
      {selectedRule && (
        <>
          <EditPolicyModal
            policyToEdit={selectedRule}
            visible={ruleDetailsModalVisible}
            onCancel={() => {
              setSelectedRule(null)
              setRuleDetailsModalVisible(false)
            }}
            onSubmit={onSubmit}
          />
          <DeletePolicyModal
            policyId={selectedRule.ruleId}
            isVisible={ruleDeletePolicyModalVisible}
            onCancel={() => setRuleDeletePolicyModalVisible(false)}
            onDelete={async () => {
              const { ruleId } = selectedRule

              await deletePolicy.mutateAsync(ruleId)

              setRuleDeletePolicyModalVisible(false)
            }}
          />
        </>
      )}

      <RuleScheduleModal
        loading={updatePolicy.isLoading}
        visible={ruleScheduleModalVisible && !!selectedRule}
        userTimeZone={userTimeZone}
        title={ruleScheduleModalTitle}
        rule={selectedRule}
        onCancel={() => {
          setSelectedRule(null)
          setRuleScheduleModalVisible(false)
        }}
        onSubmit={async ({ scheduleStart, scheduleEnd }) => {
          if (!selectedRule) {
            return
          }

          await updatePolicy.mutateAsync({
            scheduleStart,
            scheduleEnd,
            ruleId: selectedRule.ruleId,
          })

          setSelectedRule(null)
          setRuleScheduleModalVisible(false)
        }}
      />
    </>
  )
}

export default PolicyTable
