import { FC } from 'react'
import {
  NavigationBar,
  useUpdateUserState,
  useUserContext,
} from '@signifyd/components'
import { APP_MENU_ITEM_KEY } from '@signifyd/components/src/thirdGenComponents/NavigationBar/navigation.utils'
import { NavBarMenuItemWithAuth } from '@signifyd/components/src/thirdGenComponents/NavigationBar/NavigationBar.types'

type NavBarOverrides = Array<{
  key: string
  isInternalLink: NavBarMenuItemWithAuth['isInternalLink']
}>

interface Props {
  teamId?: number
}

const getOverridesConfig = (teamId?: number): NavBarOverrides => {
  const teamIdQueryParam = teamId ? `?teamId=${teamId}` : ''

  const config = [
    {
      key: APP_MENU_ITEM_KEY.DecisionCenterRules,
      isInternalLink: {
        url: `/policies/dashboard${teamIdQueryParam}`,
      },
    },
    {
      key: APP_MENU_ITEM_KEY.DecisionCenterLists,
      isInternalLink: {
        url: `/lists${teamIdQueryParam}`,
      },
    },
  ]

  return config
}

const ThirdGenNavBar: FC<Props> = ({ teamId }) => {
  const { user, userConfigs } = useUserContext()
  const { mutate: updateUserState } = useUpdateUserState(user)

  const updateUiState = (uiStateToUpdate: any): void => {
    const initialUiState = user.uiState ?? {}

    updateUserState({
      ...initialUiState,
      ...uiStateToUpdate,
    })
  }

  if (!user || !userConfigs) {
    return null
  }

  return (
    <NavigationBar
      overrides={getOverridesConfig(teamId)}
      user={user}
      userConfigs={userConfigs}
      onLanguageSelectionChange={(selectedLocale) => {
        updateUiState({ locale: selectedLocale })
      }}
      onUpdateUserState={(navBarCollapsed) => {
        updateUiState({ navBarCollapsed })
      }}
    />
  )
}

export default ThirdGenNavBar
