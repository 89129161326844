import { CHECKPOINT } from '@signifyd/http'

const checkpoint: Record<CHECKPOINT, string> & {
  label: string
} = {
  label: 'Checkpoint',
  CHECKOUT: 'Checkout',
  LOGIN: 'Login',
  RETURN: 'Return',
}

export default {
  appName: 'Decision Center',
  teamSelect: {
    label: 'Team (store)',
    notFoundContent: 'No teams (stores) found',
  },
  checkpoint,
  multiStageModalFooter: {
    ok: 'Continue',
    cancel: 'Cancel',
    previous: 'Previous',
  },
  routes: {
    lists: 'Lists',
    policies: 'Policies',
  },
  unauthorized: {
    default: 'You do not have permissions to access this page',
    noTeams: 'You need at least one active team to access Decision Center',
    noCheckpoints: 'The selected team does not have access to Decision Center',
  },
  action: 'Action',
  deletePolicyModal: {
    title: 'Delete policy?',
    okText: 'Delete',
    alert: 'This action is permanent and cannot be undone.',
    message: 'To continue, select <bold>Delete</bold>.',
  },
  duplicatePolicy: {
    nameSuffix: ' (copy)',
  },
  duplicatePolicyModal: {
    title: 'Duplicate Policy',
    okText: 'Duplicate',
    failureAlert:
      'Apologies - policy failed to duplicate to the following teams. Please try again',
    listAlert:
      'Policies that contain a list may only be duplicated to the same team.',
    select: {
      currentSuffix: '(Current)',
      label: 'Select teams to duplicate policy to:',
      placeholder: 'Select one or more teams',
      disabledOption:
        'Variables not available in this team - policy cannot be duplicated',
      tryAgainTooltip:
        "Teams can't be adjusted. To continue, try duplicating again.",
    },
    successMessage: 'Success! Policy has been duplicated.',
  },
  additionalItemsPopover: {
    noItems: 'Empty',
  },
  testTeamBanner: {
    testTeamSelected: 'Test team <bold>({{teamName}})</bold> selected',
    testTeamSelectedWithNestedText:
      'Test team <bold>({{teamName}})</bold> selected. {{text}}',
    policy:
      'Policies created on this team will not affect live customer orders.',
  },
  policyReporting: {
    timeframe: {
      label: 'Time frame',
      '24H': 'Past 24 hours',
      '7D': 'Past 7 days',
      '30D': 'Past 30 days',
      '90D': 'Past 90 days ',
      '180D': 'Past 180 days',
      '365D': 'Past 365 days ',
    },
  },
  selectedTeamBanner: {
    viewing: '{{user}} viewing on behalf of <bold>{{teamName}}</bold>',
    acting: '{{user}} acting on behalf of <bold>{{teamName}}</bold>',
  },
  noTeamsMessage: 'Please select a team to continue to Decision Center',
}
