import { FC } from 'react'
import { Alert, Card } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { Space, Text, TitleThirdGen, useIsThirdGen } from '@signifyd/components'
import { SIMULATION_TYPE } from '@signifyd/http'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import ImpactPerPolicyTable from 'pages/PublishWithSimulatorPage/components/ImpactPerPolicyTable'
import styles from './ImpactPerPolicyCard.less'

interface Props {
  pilotHasNoData: boolean
}

const ImpactPerPolicyCard: FC<Props> = ({ pilotHasNoData }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage',
  })
  const isThirdGen = useIsThirdGen()

  const [{ simulationType }] = useQueryParams(PublishPageQueryParams)
  const isPilot = simulationType === SIMULATION_TYPE.PILOT_POLICY

  return (
    <Card
      className={styles.card}
      title={
        isThirdGen ? (
          <TitleThirdGen level={2}>
            {t('simulationResult.impactPerPolicy.title')}
          </TitleThirdGen>
        ) : (
          t('simulationResult.impactPerPolicy.title')
        )
      }
      data-test-id="impactPerPolicyCard"
    >
      <Space size="sm" />
      {pilotHasNoData ? (
        <Text size="lg">{t('pilotSimulation.incompletePilotData')}</Text>
      ) : (
        <>
          {isPilot && (
            <>
              <Alert
                className={styles.pilotBanner}
                message={t(
                  'simulationResult.impactPerPolicy.pilotBannerMessage'
                )}
                type="warning"
              />
              <Space size="lg" />
            </>
          )}
          <ImpactPerPolicyTable />
        </>
      )}
    </Card>
  )
}

export default ImpactPerPolicyCard
