import { FC, useState } from 'react'
import { Modal, Flex, Switch, Select, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Moment } from 'moment-timezone'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { DatePicker, Space, Text, useUserContext } from '@signifyd/components'
import moment from 'moment-timezone'
import { useStoreState } from 'stores'
import { SIMULATION_STATUS } from '@signifyd/http'
import { renderEmailTag } from './renderEmailTag'
import {
  getMaxTimeframe,
  getMinTimeframe,
  getDatePresets,
  isDateDisabled,
} from './pilotUtils'
import styles from './PilotPreferenceModal.less'

interface Props {
  showModal: boolean
  isSaving?: boolean
  onOk: (endDate: string, emailAddresses: Array<string>) => void
  onCancel: () => void
}

export const PilotPreferenceModal: FC<Props> = ({
  showModal,
  isSaving,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage.pilotSimulation.preferencesModal',
  })
  const { username } = useUserContext().user

  const { ruleSimulation } = useStoreState((state) => state.simulationModel)

  const defaultEndDate = moment(moment.now())
    .add(7, 'days')
    .endOf('day')
    .toISOString()

  const [addEmail, setAddEmail] = useState(
    !!ruleSimulation?.emailAddresses?.length
  )
  const [emailList, setEmailList] = useState(
    ruleSimulation?.emailAddresses?.length
      ? ruleSimulation?.emailAddresses
      : [username]
  )
  const [endDate, setEndDate] = useState<string | null>(
    ruleSimulation?.endDate ?? defaultEndDate
  )

  const handleDateChange = (endDate: Moment | null): void => {
    if (!endDate) {
      setEndDate(null)

      return
    }

    setEndDate(endDate.utc().endOf('day').toISOString())
  }

  const { status, startDate, endDate: currentEndDate } = ruleSimulation ?? {}

  const simCompletedOrInProgress =
    status === SIMULATION_STATUS.FINISHED ||
    status === SIMULATION_STATUS.RUNNING

  return (
    <>
      <Modal
        title={t('title')}
        closable={false}
        maskClosable={false}
        open={showModal}
        onOk={() => {
          if (!endDate) {
            return
          }

          onOk(endDate, addEmail ? emailList : [])
        }}
        onCancel={onCancel}
        okText={t('okText')}
        cancelText={t('cancelText')}
        okButtonProps={{
          'data-analytics-id': 'confirm-pilot-preferences-btn',
          type: 'link',
          disabled: !endDate,
          loading: isSaving,
          'data-test-id': 'confirmPilotPreferences',
        }}
        cancelButtonProps={{ type: 'link' }}
        mask
        width={600}
      >
        <Flex vertical className={styles.form}>
          <Flex gap={4}>
            <Text className={styles.inputLabel}>{t('runTimeframe')}</Text>
            <Tooltip title={t('runTimeframeTooltip')}>
              <QuestionCircleOutlined className={styles.questionMark} />
            </Tooltip>
          </Flex>
          <DatePicker
            data-test-id="pilotDatePicker"
            disabled={status === SIMULATION_STATUS.FINISHED}
            placeholder={t('datePicker.placeHolder')}
            value={endDate ? moment(endDate) : null}
            presets={getDatePresets()}
            showNow={false}
            onChange={handleDateChange}
            minDate={getMinTimeframe()}
            maxDate={getMaxTimeframe(startDate)}
            disabledDate={(e) => isDateDisabled(e, currentEndDate)}
          />
          <Space size="md" />
          <Flex gap={8} align="center">
            <Tooltip title={t('switch')}>
              <Switch
                data-analytics-id="email-notification-toggle"
                size="small"
                disabled={simCompletedOrInProgress}
                onChange={(e) => setAddEmail(e)}
                value={addEmail}
              />
            </Tooltip>
            <Text>{t('emailToggle')}</Text>
          </Flex>
          {addEmail && (
            <>
              <Space size="md" />
              <Text className={styles.inputLabel}>{t('emails')}</Text>
              <Select
                disabled={simCompletedOrInProgress}
                data-test-id="emailTagInput"
                tagRender={(e) => renderEmailTag(e, username)}
                maxCount={5}
                suffixIcon={null}
                mode="tags"
                value={emailList}
                onChange={(e) => setEmailList(e)}
                open={false}
                notFoundContent={null}
                menuItemSelectedIcon={null}
              />
            </>
          )}
        </Flex>
      </Modal>
    </>
  )
}
