import { FC } from 'react'
import { Button, Dropdown } from 'antd'
import { MenuItemType } from 'antd/es/menu/interface'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { CHECKPOINT } from '@signifyd/http'

interface Props {
  teamId: number | null
  teamCheckpoints: Array<CHECKPOINT>
}

const PublishSettingsButton: FC<Props> = ({ teamId, teamCheckpoints }) => {
  const simulationEnabledCheckpoints = teamCheckpoints.filter(
    (checkpoint) => checkpoint !== CHECKPOINT.LOGIN
  )

  const { t } = useTranslation()
  const navigate = useNavigate()

  const onNavigate = (checkpoint: CHECKPOINT): void => {
    navigate(`/policies/publish/${teamId}/${checkpoint.toLowerCase()}`, {
      state: { backToPreviousPage: true },
    })
  }

  const handleNavigate = (param: MenuItemType): void => {
    const checkpoint = param.key as CHECKPOINT

    onNavigate(checkpoint)
  }

  if (simulationEnabledCheckpoints.length > 1) {
    const checkpointMenu = simulationEnabledCheckpoints.map((checkpoint) => {
      return {
        label: t(`common.checkpoint.${checkpoint}`),
        key: checkpoint,
        [`data-test-id`]: `${checkpoint}-publishOption`,
      }
    })

    return (
      <Dropdown menu={{ items: checkpointMenu, onClick: handleNavigate }}>
        <Button data-test-id="publishSettingsDropdown">
          {t('ruleDashboardPage.publishSettingsBtn')} <DownOutlined />
        </Button>
      </Dropdown>
    )
  }

  return (
    <Button
      data-test-id="publishSettingsButton"
      onClick={() => onNavigate(teamCheckpoints[0])}
    >
      {t('ruleDashboardPage.publishSettingsBtn')}
    </Button>
  )
}

export default PublishSettingsButton
