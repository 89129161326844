import { useFeatureFlags } from '@signifyd/components'

const useHasPilotPolicies = (): boolean => {
  const {
    'has-pilot-policies': { isEnabledForEnvironment },
  } = useFeatureFlags(['has-pilot-policies'])

  return isEnabledForEnvironment
}

export default useHasPilotPolicies
