import { FC, useState } from 'react'
import { Input, Button, Flex } from 'antd'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { TextThirdGen } from '@signifyd/components'
import { GetListEntriesFilters } from 'core/queries/predictionListEntries/useGetPredictionListEntries/useGetPredictionListEntries'
import DeleteListEntryPopconfirm from 'pages/ListDetailsPage/components/DeleteListEntryPopconfirm'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'
import { spacingMD } from '@signifyd/ant'

export interface ListEntriesTableHeaderProps {
  predictionListType: PREDICTION_LIST_TYPE
  filters: GetListEntriesFilters | null
  onDeleteSelectedEntries: () => void
  selectedEntryIds: Array<number>
  userCanEdit?: boolean
}

const ListEntriesTableHeaderThirdGen: FC<ListEntriesTableHeaderProps> = ({
  filters = {},
  predictionListType,
  onDeleteSelectedEntries,
  selectedEntryIds,
  userCanEdit,
}) => {
  const [searchValue, setSearchValue] = useState(
    () => filters?.searchTerm || ''
  )
  const { t } = useTranslation(undefined, {
    keyPrefix: 'listDetailsPage.listEntriesTable',
  })

  const [, setQuery] = useListDetailsPageQueryParams()

  const onSearch = (value: string): void => {
    setQuery({
      searchTerm: value,
      page: 0,
    })
  }

  return (
    <Flex gap={spacingMD} align="center" wrap>
      <Flex flex={1}>
        <Input
          data-test-id="list-entries-table-search"
          value={searchValue}
          allowClear
          placeholder={t(`searchPlaceholder.${predictionListType}`)}
          onChange={(e) => setSearchValue(e.target.value)}
          onPressEnter={() => onSearch(searchValue)}
          onClear={() => onSearch('')}
          size="middle"
        />
      </Flex>

      <Flex flex={1} gap={spacingMD} align="center" wrap>
        <Button
          type="primary"
          disabled={!searchValue.length}
          onClick={() => onSearch(searchValue)}
          data-test-id="listEntriesTableBulkSearchButton"
          data-analytics-id="list-entries-table-bulk-search-button"
        >
          {t('searchButton')}
        </Button>

        {userCanEdit && (
          <DeleteListEntryPopconfirm
            count={selectedEntryIds.length}
            placement="right"
            onConfirm={onDeleteSelectedEntries}
          >
            <Button
              type="primary"
              disabled={!selectedEntryIds.length}
              data-test-id="listEntriesTableBulkDeleteButton"
            >
              {t('deleteListEntryPopconfirm.bulkDeleteEntriesBtn', {
                count: selectedEntryIds.length,
              })}
            </Button>
          </DeleteListEntryPopconfirm>
        )}

        {selectedEntryIds.length > 0 && (
          <TextThirdGen>
            {t('selections.count', {
              count: selectedEntryIds.length,
            })}
          </TextThirdGen>
        )}
      </Flex>
    </Flex>
  )
}

export default ListEntriesTableHeaderThirdGen
