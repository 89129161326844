export default {
  backBtn: 'All lists',
  createdBy: 'Created by',
  sidePanel: {
    listSize: 'Items on list',
    numRulesInList: 'Policies using list',
    ruleDropdownTitle: 'VIEW POLICIES USING LIST',
    descriptionTitle: 'Description',
    expirationConfiguration: 'Expiration configuration',
    expirationCount: 'Expirations on list',
    deleteExpirationSuccess: 'Success! Expiration deleted from list.',
    createExpirationSuccess: 'Success! Expiration added to list.',
    updateExpirationSuccess: 'Success! Expiration updated to list.',
    expirationForm: {
      errorMessage: {
        updateExpiration:
          'Apologies — our system encountered an error updating expiration. Try again.',
        deleteExpiration:
          'Apologies — our system encountered an error deleting expiration. Try again.',
        addExpiration:
          'Apologies — our system encountered an error adding expiration. Try again.',
      },
      listTypeTag: {
        EMAIL: 'email',
        CARD_BIN: 'credit card BIN',
        IP_ADDRESS: 'IP address',
        DEVICE_ID: 'device ID',
        ITEM_ID: 'item ID',
        DISCOUNT_CODE: 'discount code',
        PHONE_NUMBER: 'phone number',
        ADDRESS: 'address',
        RESELLER_ABUSE: 'reseller abuse',
        PROMO_ABUSE: 'promo abuse',
        EMAIL_DOMAIN: 'email domain',
        MEMBERSHIP_ID: 'membership ID',
        ACCOUNT_NUMBER: 'account number',
        TAX_ID: 'tax ID',
      },
      applyButton: 'Apply',
      deleteButton: 'Delete',
      cancelButton: 'Cancel',
      deleteModalTitle: 'Delete expiration?',
      deleteMessage: 'To continue, select <bold>Delete</bold>.',
      deleteNotice: 'This action is permanent and cannot be undone.',
      expirationTypes: {
        ORDERS: 'Order',
        ORDERS_other: 'Orders',
        CHARGEBACKS: 'Chargeback',
        CHARGEBACKS_other: 'Chargebacks',
        MINUTES: 'Minute',
        MINUTES_other: 'Minutes',
        HOURS: 'Hour',
        HOURS_other: 'Hours',
        DAYS: 'Day',
        DAYS_other: 'Days',
        WEEKS: 'Week',
        WEEKS_other: 'Weeks',
        MONTHS: 'Month',
        MONTHS_other: 'Months',
      },
      formLabelExpireAfter: 'Expire {{listType}} after:',
      selectPlaceholder: 'Select one',
      inputPlaceHolder: 'Input',
    },
    expirationTypesLowerCase: {
      ORDERS: 'order',
      ORDERS_other: 'orders',
      CHARGEBACKS: 'chargeback',
      CHARGEBACKS_other: 'chargebacks',
      MINUTES: 'minute',
      MINUTES_other: 'minutes',
      HOURS: 'hour',
      HOURS_other: 'hours',
      DAYS: 'day',
      DAYS_other: 'days',
      WEEKS: 'week',
      WEEKS_other: 'weeks',
      MONTHS: 'month',
      MONTHS_other: 'months',
    },
    expirationFormTooltip:
      'Expiration will apply to all items including those added later',
    addExpiration: 'Add expiration',
    addedExpiration: 'Added expiration',
    manageExpiration: 'Manage expiration',
    cancelExpiration: 'Cancel',
    listHasNoExpirations: 'List has 0 expirations.',
    listWillExpireAfter: '{{listName}} will expire after {{count}} {{type}}.',
    lastActivity: 'Last activity',
    listActivity: '{{action}} list',
    lastEventText: {
      CREATE_LIST: 'created {{type}} list.',
      UPDATE_LIST: 'edited details of list.',
      DELETE_LIST: 'deleted {{type}} list.',
      CREATE_LIST_ENTRY_one: 'added an item to list.',
      CREATE_LIST_ENTRY_other: 'added {{count}} items to list.',
      REPLACE_LIST_ENTRY: 'replaced all items in list.',
      DELETE_LIST_ENTRY_one: 'deleted an item from list.',
      DELETE_LIST_ENTRY_other: 'deleted {{count}} items from list.',
      CREATE_LIST_EXPIRATION: 'added expiration to list.',
      DELETE_LIST_EXPIRATION: 'deleted expiration from list.',
      EDIT_LIST_EXPIRATION: 'edited expiration to list.',
    },
    actions: 'Actions',
    addItems: 'Add items',
    downloadCSV: 'Download CSV',
    replaceAll: 'Replace all',
    noEntriesTooltip: "Available when there's at least one item in list",
    deleteList: 'Delete list',
    deleteListTooltip:
      'List is being used in one or more policies and cannot be deleted at this time',
    createdBy: 'Created by',
    createdByText: '{{name}} ',
  },
  createListEntriesModal: {
    title: 'Add items',
    description: {
      newList:
        'List created <em> — </em> now you can add items to this {{type}} list. How would you like to add items?',
      existingList: 'How would you like to add items to this list?',
    },
    descriptionListType: {
      EMAIL: 'email',
      CARD_BIN: 'credit card BIN',
      IP_ADDRESS: 'IP address',
      DEVICE_ID: 'device ID',
      ITEM_ID: 'item ID',
      DISCOUNT_CODE: 'discount code',
      PHONE_NUMBER: 'phone number',
      ADDRESS: 'address',
      PROMO_ABUSE: 'promo abuse',
      RESELLER_ABUSE: 'reseller abuse',
      EMAIL_DOMAIN: 'email domain',
      MEMBERSHIP_ID: 'membership ID',
      ACCOUNT_NUMBER: 'account number',
      TAX_ID: 'tax ID',
    },
    cancelText: 'Cancel',
    okText: {
      default: 'Add',
      hasDuplicate: 'Finish',
    },
  },
  replaceListEntriesModal: {
    title: 'Replace all items',
    info: 'This action will delete existing items and replace with new.',
    description: 'Replace all items on list using one of the two methods',
    cancelText: 'Cancel',
    okText: 'Replace',
  },
  listEntriesForm: {
    uploadMethodLabel: {
      STRING: 'Add manually',
      CSV: 'Upload CSV file',
    },
    csvHelpHint:
      'To avoid issues, each value should be in one column, no commas, one value per row and file size less than 5MB.',
    csvDraggerContent: 'Choose CSV file or drag it here',
    manualInputPlaceholder:
      'Enter up to {{limit}} separated by commas, spaces, or new lines',
    manualInputLimitReachedError:
      'List has exceeded the maximum number of {{limit}} items',
    address: {
      header:
        'The fields below should be completed with exact address matches only',
      streetAddressTitle: 'Street Address',
      streetAddressPlaceholder: 'Enter street address',
      unitTitle: 'Unit/suite (optional)',
      unitPlaceholder: 'Enter unit/suite no.',
      cityTitle: 'City',
      cityPlaceholder: 'Enter city',
      provinceTitle: 'Province/state',
      provincePlaceholder: 'Enter province/state',
      postalCodeTitle: 'Postal code',
      postalCodePlaceholder: 'Enter postal code',
      countryTitle: 'Country',
      countryPlaceholder: 'Enter country',
    },
    closeListEntriesModalPopconfirm: {
      title: 'Confirm before closing',
      description: 'Any changes will be lost',
      okText: 'Close',
      cancelText: 'Keep editing',
    },
    customError: {
      EMAIL_DOMAIN: 'Email domain must not have @ character',
    },
  },
  resolveDuplicateEntriesForm: {
    warningTitle:
      'Some items already exist in list. Please resolve before proceeding.',
    radioGroupHelpHint: 'To resolve duplicates, choose one',
    discardLabel: 'Discard duplicates (recommended)',
    discardDescription:
      'Duplicated items will be discarded and items not already in list added',
    overwriteLabel: 'Keep duplicates and overwrite existing',
    overwriteDescription:
      'Items already in list will be overwritten including <bold>Date added</bold> and <bold>Added by</bold>',
  },
  listEntriesTable: {
    searchButton: 'Search',
    searchPlaceholder: {
      EMAIL: 'Search email',
      CARD_BIN: 'Search credit card BIN',
      IP_ADDRESS: 'Search IP address',
      DEVICE_ID: 'Search device ID',
      ITEM_ID: 'Search item ID',
      DISCOUNT_CODE: 'Search discount code',
      PHONE_NUMBER: 'Search phone number',
      ADDRESS: 'Search address',
      EMAIL_DOMAIN: 'Search email domain',
      TAX_ID: 'Search tax ID',
      MEMBERSHIP_ID: 'Search membership ID',
      ACCOUNT_NUMBER: 'Search account number',
      PROMO_ABUSE: 'Search discount code',
      RESELLER_ABUSE: 'Search item ID',
    },
    valueColTitleByListType: {
      EMAIL: 'Email address',
      CARD_BIN: 'Credit card BIN',
      IP_ADDRESS: 'IP address',
      DEVICE_ID: 'Device ID',
      ITEM_ID: 'Item ID',
      DISCOUNT_CODE: 'Discount code',
      PHONE_NUMBER: 'Phone number',
      ADDRESS: 'Address',
      EMAIL_DOMAIN: 'Email domain',
      TAX_ID: 'Tax ID',
      MEMBERSHIP_ID: 'Membership ID',
      ACCOUNT_NUMBER: 'Account number',
      PROMO_ABUSE: 'Discount code',
      RESELLER_ABUSE: 'Item ID',
    },
    listTypeTooltip:
      'List items below will match on orders based on the raw data sent to Signifyd',
    columnTitle: {
      createdAt: 'Date added',
      createdByName: 'Added by',
    },
    entryDropdown: {
      delete: 'Delete from list',
    },
    pagination: {
      totalNumber: '{{start}} - {{end}} of {{total}} items',
    },
    loading: {
      title: 'Loading',
      description: 'Please wait',
    },
    noSearchResults: {
      title: 'No results found',
      description: 'Please try another keyword',
    },
    noListEntries: {
      title: 'No items in list yet',
      description: 'Click <bold>Add items</bold> for options',
    },
    selections: {
      count_one: 'Selected {{count}} item',
      count_other: 'Selected {{count}} items',
    },
    deleteListEntryPopconfirm: {
      bulkDeleteEntriesBtn: 'Delete from list',
      title_one: 'Are you sure you want to delete the item?',
      title_other: 'Are you sure you want to delete those items?',
      description_one: 'This will delete it permanently.',
      description_other: 'This will delete them permanently.',
      okText: 'Delete',
      cancelText: 'Cancel',
    },
  },
  listPageOrders: {
    toggleLabel: 'Enable add and remove from order',
    toggleTooltip:
      '<p>This action allows items to be added and removed directly from order details.</p> Formatting of list items added or removed may appear different in Orders.',
    enableError:
      'Apologies — our system failed to enable add and remove. Try again.',
    disableError:
      'Apologies — our system failed to disable add and remove. Try again.',
    popover: {
      enable: {
        title: 'Enable add and remove?',
        description:
          'This list will be available in orders immediately. You can disable here at any time.',
        okButton: 'Enable',
        cancelButton: 'Cancel',
      },
      disable: {
        title: 'Disable add and remove?',
        description:
          'This list will be hidden from orders immediately. You can enable again here at any time.',
        okButton: 'Disable',
        cancelButton: 'Cancel',
      },
    },
  },
  totalListPageItems: '{{count}} total list items',
}
