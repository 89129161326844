import { FC } from 'react'
import { Flex, Skeleton } from 'antd'
import { spacingMD } from '@signifyd/ant'
import styles from './ListEntriesTableSkeleton.less'

const skeletonConfig = {
  inputWidth: 700,
  nodeHeight: '320px',
  nodeWidth: '100%',
}

const ListEntriesTableSkeleton: FC = () => {
  return (
    <Flex vertical gap={spacingMD} className={styles.container}>
      <Flex gap={spacingMD} justify="space-between">
        <Flex flex={2}>
          <Skeleton.Input
            style={{ width: skeletonConfig.inputWidth }}
            className={styles.container}
            active
          />
        </Flex>
        <Flex flex={1} gap={spacingMD}>
          <Skeleton.Button active size="default" />
          <Skeleton.Button active size="default" />
        </Flex>
      </Flex>
      <Flex gap={spacingMD} justify="space-between" align="center">
        <Skeleton active paragraph={{ rows: 1 }} />
        <>
          <Skeleton.Button active size="default" />
          <Skeleton.Button active size="default" />
        </>
      </Flex>
      <Skeleton.Node
        active
        style={{
          height: skeletonConfig.nodeHeight,
          width: skeletonConfig.nodeWidth,
        }}
      />
    </Flex>
  )
}

export default ListEntriesTableSkeleton
