import { FC } from 'react'
import { Radio, Col, Row, Button } from 'antd'
import { RadioChangeEvent } from 'antd/es/radio'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { RangePickerValue, Text } from '@signifyd/components'
import { SIMULATION_TYPE } from '@signifyd/http'
import SimulationDatePicker from 'pages/PublishWithSimulatorPage/components/SimulationDatePicker'
import { disabledSimulationDate } from 'pages/PublishWithSimulatorPage/components/SimulationDatePicker/SimulationDatePicker.util'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { VIEW_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { useStoreState, useStoreActions } from 'stores'
import { simulationDateFormat } from 'stores/simulation'
import styles from './SimulationResultHeader.less'

export interface SimulationDatePickerProps {
  isPilotPolicy?: boolean
  dateRangeValue: NonNullable<RangePickerValue>
  setDateRange: (dateRangeValue: RangePickerValue) => void
  userTimeZone: string
}

const SimulationResultHeader: FC<SimulationDatePickerProps> = ({
  isPilotPolicy,
  setDateRange,
  dateRangeValue,
  userTimeZone,
}: SimulationDatePickerProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage.simulationResult',
  })

  const { teamId } = useTypedRouteParams()
  const [{ editedPolicyId, ruleSetId, simulationType, viewType }, setFilters] =
    useQueryParams(PublishPageQueryParams)

  const {
    noInvestigations,
    isLoading: { createRuleSimulation: isLoading },
  } = useStoreState((state) => state.simulationModel)
  const { createRuleSimulation } = useStoreActions(
    (actions) => actions.simulationModel
  )

  const [simulationStartDate, simulationEndDate] = dateRangeValue ?? [
    null,
    null,
  ]
  const oneMonthAgo = moment().subtract(1, 'months')
  const endOfToday = moment().endOf('day')
  const isPilot = simulationType === SIMULATION_TYPE.PILOT_POLICY

  const createSimulationForTeam = (teamId: number): void => {
    const startDate = simulationStartDate
      ?.clone()
      .startOf('day')
      .utc()
      .format(simulationDateFormat)

    const endDate = simulationEndDate
      ?.clone()
      .endOf('day')
      .utc()
      .format(simulationDateFormat)

    if (startDate && endDate) {
      createRuleSimulation({
        ruleSetId: ruleSetId!,
        teamId,
        startDate,
        endDate,
        editedRuleId: editedPolicyId ?? undefined,
        simulationType,
      })
    }
  }

  const handleChangeViewTypeRadio = (event: RadioChangeEvent): void => {
    const viewType: VIEW_TYPE = event.target.value

    setFilters({ viewType })
  }

  if (!teamId) {
    return null
  }

  return (
    <Row justify="space-between" align="middle" className={styles.header}>
      <Col>
        <Text size="lg" weight="semibold" className={styles.title}>
          {t(isPilot ? 'pilotTitle' : 'title')}
        </Text>
      </Col>
      <Col>
        <Row align="middle" gutter={[16, 4]} justify="space-between">
          {viewType === VIEW_TYPE.GMV && (
            <Col
              className={styles.currencyText}
              data-test-id="currency-label-col"
            >
              <Text type="secondary" size="xs">
                {t('currencyLabel')}
              </Text>
            </Col>
          )}
          {!noInvestigations && (
            <Col data-test-id="view-count-radio-col">
              <Radio.Group
                defaultValue={viewType || VIEW_TYPE.COUNT}
                onChange={handleChangeViewTypeRadio}
              >
                <Radio.Button
                  value={VIEW_TYPE.COUNT}
                  data-test-id="view-count-radio-option"
                  data-analytics-id="view-count-radio-option"
                >
                  {t('countView')}
                </Radio.Button>
                <Radio.Button
                  value={VIEW_TYPE.GMV}
                  data-test-id="gmv-radio-option"
                  data-analytics-id="gmv-radio-option"
                >
                  {t('gmvView')}
                </Radio.Button>
              </Radio.Group>
            </Col>
          )}
          <Col>
            <SimulationDatePicker
              value={dateRangeValue}
              onChange={(dateRange) => setDateRange(dateRange)}
              userTimeZone={userTimeZone}
            />
          </Col>
          {!isPilotPolicy && (
            <Col>
              <Button
                type="primary"
                loading={isLoading}
                disabled={
                  isLoading ||
                  !(simulationStartDate && simulationEndDate) ||
                  !ruleSetId ||
                  disabledSimulationDate(
                    oneMonthAgo,
                    endOfToday,
                    simulationStartDate
                  )
                }
                data-test-id="runSimulationButton"
                data-analytics-id="run-simulation-button"
                onClick={() => createSimulationForTeam(teamId)}
              >
                {t('runSimulationBtn')}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default SimulationResultHeader
