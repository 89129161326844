import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PredictionListLastEvent, PREDICTION_LIST_TYPE } from '@signifyd/http'
import { TextThirdGen } from '@signifyd/components'
import FormatDate from 'core/components/FormatDate'
import useUserTimeZone from 'core/hooks/useUserTimeZone'

export interface Props {
  updatedAt: string
  updatedByName: string
  listType: PREDICTION_LIST_TYPE
  lastEvent: PredictionListLastEvent
}

const ListLastEventThirdGen: FC<Props> = ({
  updatedAt,
  listType,
  updatedByName,
  lastEvent,
}) => {
  const { t } = useTranslation()
  const userTimeZone = useUserTimeZone()

  return (
    <TextThirdGen type="secondary" size="sm" data-test-id="listLastEvent">
      <FormatDate
        format="M/D/YYYY h:mm A"
        UTCTimestamp={updatedAt}
        timeZone={userTimeZone}
        showTimeZoneAbbr
      />
      {` ${updatedByName} ${t(
        `listDetailsPage.sidePanel.lastEventText.${lastEvent.eventType}`,
        {
          type: t(`listCommon.listTypeLowerCase.${listType}`),
          count: lastEvent.affectedEntryCount || 0,
          interpolation: { escapeValue: false },
        }
      )}`}
    </TextThirdGen>
  )
}

export default ListLastEventThirdGen
