import { useParams } from 'react-router'
import { CHECKPOINT } from '@signifyd/http'
import { isValidCheckpoint } from 'core/utils/searchParams/typeGuards'
import {
  PublishWithSimulatorPageParams,
  PublishWithSimulatorPageUrlParams,
} from '../../PublishWithSimulatorPage.utils'

const getTypedRouteParams = ({
  teamId,
  checkpoint,
}: PublishWithSimulatorPageUrlParams): PublishWithSimulatorPageParams => {
  // TODO FET-1827 https://signifyd.atlassian.net/browse/FET-1827
  // This could probably use zod too, just a separate function (one for the route params, another for the search params)
  return {
    teamId: Number(teamId) ?? undefined,
    checkpoint: isValidCheckpoint(checkpoint)
      ? (checkpoint.toUpperCase() as CHECKPOINT)
      : undefined,
  }
}

export const useTypedRouteParams = (): PublishWithSimulatorPageParams => {
  const { teamId, checkpoint } = useParams()

  const typedRouteParams = getTypedRouteParams({
    teamId,
    checkpoint,
  })

  return typedRouteParams
}
