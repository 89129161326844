import {
  CHECKPOINT,
  getRuleSet,
  listRuleSimulations,
  RULE_SET_STAGE,
  RuleSimulation,
  SIMULATION_STATUS,
  SIMULATION_TYPE,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

export const GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX = 'listPilotSimulations'

const useGetPilotSimulations = (
  hasPilotPolicies: boolean,
  teamId?: number | null,
  checkpoint?: CHECKPOINT | null
): UseQueryResult<RuleSimulation> => {
  return useQuery({
    queryKey: [GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX, teamId, checkpoint],
    enabled: !!teamId && !!checkpoint && hasPilotPolicies,
    queryFn: async () => {
      const { data } = await listRuleSimulations({
        teamId: teamId!,
        simulationType: SIMULATION_TYPE.PILOT_POLICY,
        checkpoint: checkpoint!,
        statuses: `${SIMULATION_STATUS.RUNNING},${SIMULATION_STATUS.FINISHED}`,
      })

      const activePilotSimulations = data.data.filter(
        (simulation) => simulation.status !== SIMULATION_STATUS.CANCELED
      )

      if (!activePilotSimulations.length) {
        return null
      }

      const runningSimulation = activePilotSimulations.find(
        (simulation) => simulation.status === SIMULATION_STATUS.RUNNING
      )

      if (runningSimulation) {
        return runningSimulation
      }

      const finishedSimulation = activePilotSimulations.find(
        (simulation) => simulation.status === SIMULATION_STATUS.FINISHED
      )

      if (finishedSimulation?.ruleSetId) {
        const { data: ruleSet } = await getRuleSet(finishedSimulation.ruleSetId)

        if (ruleSet.ruleSetStage === RULE_SET_STAGE.PUBLISHED) {
          return null
        }
      }

      return finishedSimulation
    },
  })
}

export default useGetPilotSimulations
