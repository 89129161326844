import { CHECKPOINT } from '@signifyd/http'

const viewOrdersButton: Record<CHECKPOINT, string> = {
  CHECKOUT: 'View orders',
  LOGIN: 'View orders',
  RETURN: 'View returns',
}

export default {
  ruleValidationResult: {
    matching: {
      title: 'Validate condition match',
    },
    nonMatching: {
      title: 'Validate condition non-match',
    },
  },
  summaryHeaderActions: {
    menu: {
      editDetails: 'Edit name or description',
      editConfiguration: 'Edit configuration',
      duplicate: 'Duplicate policy',
      delete: 'Delete policy',
    },
    success: 'Policy deleted!',
  },
  policyReports: {
    title: {
      CHECKOUT: 'Affected orders',
      RETURN: 'Affected returns',
    },
    timeframe: {
      label: 'Time frame',
      '24H': 'Past 24 hours',
      '7D': 'Past 7 days',
      '30D': 'Past 30 days',
      '90D': 'Past 90 days ',
      '180D': 'Past 180 days',
      '365D': 'Past 365 days ',
    },
    timezone: {
      label: {
        CHECKOUT: 'Policy hits are displayed in UTC time zone',
        RETURN: 'All metrics are pulled in UTC time zone',
      },
      tooltip: {
        CHECKOUT:
          'Policy hits are displayed in UTC time zone only. This will change some of the hit’s reported dates for merchants outside of UTC time zone.',
        RETURN:
          'Policy hits are displayed in UTC time zone only. This will change some of the hit’s reported dates for merchants outside of UTC time zone.',
      },
    },
    hitsByCount: {
      label: {
        CHECKOUT: 'Hits by count',
        CHECKOUT_hits: 'Hits by count: {{hits}}',
        RETURN_hits: 'Hits by count: {{hits}}',
        RETURN: 'Hits by count',
      },
      tooltip: {
        CHECKOUT:
          'Hits by count is the number of orders impacted by this policy over the selected time frame.',
        RETURN:
          'Hits by count is the number of returns impacted by this policy over the selected time frame.',
      },
    },
    percentageOfTotalCount: {
      label: {
        CHECKOUT: '% total order count',
        RETURN: '% total returns count',
      },
      tooltip: {
        CHECKOUT:
          'This percentage is calculated by dividing hits by count over all order traffic.',
        RETURN:
          'This percentage is calculated by dividing this policy’s hits by count over all return traffic.',
      },
    },
    percentageOfTotalAffectedByPolicies: {
      label: {
        CHECKOUT: '% total orders affected by policies',
        RETURN: '% total returns affected by policies',
      },
      tooltip: {
        CHECKOUT:
          'This percentage is calculated by dividing this policy’s hits by count over hits by count for all orders.',
        RETURN:
          'This percentage is calculated by dividing this policy’s hits by count over hits by count for all returns.',
      },
    },
    hitsByGmv: {
      label: {
        CHECKOUT: 'Hits by GMV',
        CHECKOUT_hits: 'Hits by GMV: ${{hits}}',
        RETURN: 'Hits by GMV',
        RETURN_hits: 'Hits by GMV: ${{hits}}',
      },
      tooltip: {
        CHECKOUT:
          'Hits by GMV is the aggregate order amount impacted by this policy over the selected time frame.',
        RETURN:
          'Hits by GMV is the aggregate return amount impacted by this policy over the selected time frame.',
      },
    },
    percentageOfTotalGmv: {
      label: {
        CHECKOUT: '% total order GMV',
        RETURN: '% total returns GMV',
      },
      tooltip: {
        CHECKOUT:
          'This percentage is calculated by dividing this policy’s hits by GMV over GMV for all orders.',
        RETURN:
          'This percentage is calculated by dividing this policy’s hits by GMV over GMV for all returns.',
      },
    },
    percentageOfTotalGmvAffectedByPolicies: {
      label: {
        CHECKOUT: '% total GMV affected by policies',
        RETURN: '% total returns GMV affected by policies',
      },
      tooltip: {
        CHECKOUT:
          'This percentage is calculated by dividing this policy’s hits by GMV over GMV for orders impacted by all policies.',
        RETURN:
          'This percentage is calculated by dividing this policy’s hits by GMV over GMV for returns impacted by all policies.',
      },
    },
    error:
      'Apologies — our system encountered an error loading reports. Try again.',
    viewOrdersButton,
  },
}
