export default {
  header: {
    title: {
      CHECKOUT: 'Publishing settings for checkout',
      LOGIN: 'Publishing settings for login',
      RETURN: 'Publishing settings for return',
    },
    cancelBtn: 'Cancel',
    publishBtn: 'Publish now',
    publishConfirmation: 'Are you sure?',
    publishRecommendation:
      'We recommend simulating your policy impact before publishing to minimize unexpected behavior.',
    publishPopconfirmOkText: 'Continue',
    publishPopconfirmCancelText: 'Cancel',
    disabledPublishTooltip: 'Simulation required before publishing',
  },
  createSimulation: {
    title: 'Simulate with historical data (recommended)',
    description:
      'Pick a date range and run simulation against previous data to see potential impacts. Simulation only runs on ranked policies.',
    runSimulationBtn: 'Run simulation',
    presetRangeToday: 'Today',
    presetRangeLast7Days: 'Last 7 days',
  },
  runningSimulation: {
    description:
      'Please wait while simulation for {{startDate}} - {{endDate}} processes',
    cancelSimulationBtn: 'Cancel simulation',
    cancelSimulationPopconfirm: {
      title: 'Cancel simulation?',
      description: 'Any progress will be lost',
      okText: 'Cancel simulation',
      cancelText: 'Keep simulation',
    },
  },
  pilotSimulation: {
    header: {
      title: 'Pilot settings',
      buttons: {
        publishSimulation: 'Publish to live traffic',
        publishSimulationPopover: {
          title: 'Are you sure?',
          description:
            'We recommend waiting for results or simulating on historical traffic before publishing to minimize unexpected behavior.\n\nPublishing policies to live traffic will begin to affect live orders.',
        },
        goToSimulation: 'Go to simulation',
        endPilot: 'End pilot',
        endPilotPopover: {
          title: 'Are you sure?',
          description: 'Previous order data from this pilot will be discarded.',
        },
        savePilot: 'Save pilot',
        savePilotPopover: {
          title: 'Are you sure?',
          description:
            'Previous order data from this pilot will be discarded. To keep a record of your orders you can download using the button below:',
          download: 'Download',
        },
        runPilot: 'Run pilot',
        backToPolicies: 'Back to policies',
        cancel: 'Cancel',
        preferences: 'Preferences',
      },
    },
    title: 'Pilot with live data',
    createPilot: 'Configure a pilot',
    description:
      'A pilot is best utilized when live data is more relevant for testing and you can wait for results before publishing.',
    pilotRunLength:
      'Run a pilot for up to two weeks. Results will be available continuously --- edit, pause and re-run a pilot anytime to ensure new policies perform as intended.',
    preferencesModal: {
      okText: 'Save',
      cancelText: 'Cancel',
      title: 'Pilot preferences',
      runTimeframe: 'Pilot end date',
      runTimeframeTooltip:
        'Max timeframe for a pilot test is 2 weeks. Once started an end date can only be edited to be within 2 weeks of start date',
      notifications: 'Notifications',
      emailToggle: 'Email notifications when complete',
      emails: 'Email addresses (Max 5)',
      datePicker: {
        placeHolder: 'Select a date',
        twentyFourHours: '24 hours',
        fortyEightHours: '48 hours',
        oneWeek: '1 week',
        twoWeeks: '2 weeks',
      },
      switch: 'Emails cannot be added once a pilot is running',
    },
    existingPilot:
      'Unable to pilot at this time because a pilot is currently running. To make changes to that pilot, pause or edit --- to start a different pilot wait to publish or discard. ',
    viewActivePilot: 'View active pilot',
    incompletePilotData: 'Waiting',
    tbd: 'TBD',
    tbdOrders: 'Tested on TBD orders',
    liveTBD: 'Live: TBD',
    simulationResults: {
      noResults:
        'Unable to find orders within selected timeframe. \nWe recommend you try another timeframe OR pilot with live data.',
      configurePilotDisabled:
        'Unable to pilot at this time because a pilot is currently running.',
      configurePilot: 'Configure a pilot if live data is more relevant',
      alternative: 'Alternatively, publish now if you need to bypass testing.',
    },
    pilotCreatedMessage: 'Success! Pilot is running',
    updatePilotErrorMessage:
      'Apologies — our system encountered an error updating pilot simulation. Try again.',
  },
  distributionSankey: {
    before: 'Before change',
    beforePilot: 'Live',
    after: 'After change',
    afterPilot: 'Pilot',
    accept: 'Accept',
    reject: 'Reject',
    hold: 'Hold',
    unaffected: 'Default',
    challenge: 'Challenge',
    credit: 'Credit',
    noDistributionGraphData: 'No data found for distribution graph',
  },
  simulationResult: {
    title: 'Simulation',
    pilotTitle: 'Pilot results',
    currencyLabel: 'Currency USD',
    countView: 'Count',
    gmvView: 'GMV',
    runSimulationBtn: 'Run simulation',
    validateConditionBtn: 'Run',
    impactPerPolicy: {
      title: 'Impact per policy',
      rank: 'Rank',
      name: 'Policy',
      action: 'Action',
      before: 'Before change',
      beforePilot: 'Live',
      after: 'After change',
      afterPilot: 'Pilot',
      change: '% change',
      changeTooltip:
        'Relative change between before and after values in percentage',
      pilotBannerMessage:
        'Policies in pilot mode are not factored into historical simulation results',
    },
    infoMessage:
      'Simulation will be based on randomly sampled {{simulationSizeLimit}} orders based on your selected time range',
    ruleIdsWarningMessage:
      'Looks like you have changes in the policy rank. Please run the simulation again.',
    pilotRuleIdsWarningMessage:
      'Looks like you have changes in the policy rank. Changes will be discarded if not saved.',
    caseIdValidation: 'Case ID can only contain numeric characters.',
  },
  publishStepsBar: {
    stepOne: 'Rank policies to publish',
    stepOnePilot: 'Rank policies to pilot',
    stepOnePilotCompleteTooltip:
      'Policy ranking cannot be changed on completed pilots',
    stepTwo: 'Run a test to see the impact (required)',
  },
  simulationDistributionTable: {
    title: 'After change',
    titlePilot: 'Pilot',
    before: 'Before change',
    beforePilot: 'Live',
    accept: 'Accept',
    reject: 'Reject',
    hold: 'Hold',
    unaffected: 'Default',
    credit: 'Credit',
    challenge: 'Challenge',
    total: 'Total',
  },
  distributionCard: {
    title: 'Distribution',
  },
  totalImpactCard: {
    title: 'Total impact',
    numberBeforeSimulation: 'Before: {{formattedNumber}}',
    numberLivePilot: 'Live: {{formattedNumber}}',
    tooltip: 'Relative change between values of before and after in percentage',
    statisticLabels: {
      CHECKOUT: {
        extraContent_one: 'Simulated on {{formattedNumber}} order',
        extraContentPilot_one: 'Tested on {{formattedNumber}} order',
        extraContent_other: 'Simulated on {{formattedNumber}} orders',
        extraContentPilot_other: 'Tested on {{formattedNumber}} orders',
        total: 'Total orders affected',
        ACCEPT: 'Orders accepted',
        REJECT: 'Orders rejected',
        HOLD: 'Orders held',
      },
      LOGIN: {
        extraContent_one: 'Simulated on {{formattedNumber}} login',
        extraContentPilot_one: 'Tested on {{formattedNumber}} login',
        extraContent_other: 'Simulated on {{formattedNumber}} logins',
        extraContentPilot_other: 'Tested on {{formattedNumber}} logins',
        total: 'Total login attempts',
        ACCEPT: 'Logins accepted',
        REJECT: 'Logins rejected',
        CHALLENGE: 'Logins challenged',
      },
      RETURN: {
        extraContent_one: 'Simulated on {{formattedNumber}} return',
        extraContentPilot_one: 'Tested on {{formattedNumber}} return',
        extraContent_other: 'Simulated on {{formattedNumber}} returns',
        extraContentPilot_other: 'Tested on {{formattedNumber}} returns',
        total: 'Total return attempts',
        ACCEPT: 'Returns accepted',
        REJECT: 'Returns rejected',
        HOLD: 'Returns held',
        CREDIT: 'Returns credited',
      },
    },
    download: {
      title: 'Download orders',
      tooltip: 'Download affected orders by simulation as .csv',
      tooltipPilot: 'Download orders impacted by pilot',
    },
  },
  ruleSetDnD: {
    helpHint:
      'Drag a policy <bold>To publish</bold> section and change ranking. Only ranked policies will impact order traffic.',
    publishedTitle: 'To publish',
    othersTitle: 'Not to publish',
    pilotTitle: 'Pilot',
    allPoliciesTitle: 'All policies',
    publishedPlaceholder: 'No policies to be published',
    othersPlaceholder: 'New policies will appear here before publishing',
    pilotChangesWarning:
      'Any changes here will cause the current pilot to be discarded.',
  },
  ruleSetDnDDraggableRule: {
    moveToPublish: 'Move <bold>To publish</bold>',
    moveToOthers: 'Move to <bold>Not to publish</bold>',
  },
  ruleScheduleModal: {
    addSchedule: 'Add schedule',
    manageSchedule: 'Manage schedule',
  },
  noResultsFound: {
    title: 'No results found',
    description:
      'Unable to find orders within selected timeframe. We recommend you try another timeframe.',
  },
  fallbackErrorMessage: {
    fallback:
      'Apologies — our system encountered an error.  If problem persists please contact support.',
    pollSimulation:
      'Apologies — our system encountered an error while running simulation. Try again.',
    cancelSimulation: 'Simulation canceled',
  },
  cancelPublishModal: {
    title: 'Cancel publishing?',
    description: 'All progress will be lost.',
    description_edit:
      'All progress will be lost and policy changes will not be saved.',
    ok: 'Cancel',
    cancel: 'Keep progress',
  },
  disabledTooltip:
    'Cannot change policy ranking when editing. To change rank, publish the edited policy and then select “Change Rank” from policy overview.',
  ruleName: {
    created: 'created',
  },
}
