import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import {
  RuleSimulation,
  updateRuleSimulation,
  UpdateRuleSimulationRequest,
} from '@signifyd/http'
import { message } from 'antd'
import { i18nInstance } from '@signifyd/components'
import { AxiosError, AxiosResponse } from 'axios'
import { getHTTPErrorStatus } from 'core/http'
import { GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX } from '../useGetPilotSimulations/useGetPilotSimulations'

interface UpdatePayload {
  simulationId: number
  payload: UpdateRuleSimulationRequest
}

const useUpdatePilotSimulation = (): UseMutationResult<
  AxiosResponse<RuleSimulation>,
  AxiosError,
  UpdatePayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ simulationId, payload }) => {
      return updateRuleSimulation(simulationId, payload)
    },
    onSuccess: ({ data: ruleSimulation }) => {
      queryClient.invalidateQueries([
        GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX,
        ruleSimulation.teamId,
        ruleSimulation.checkpoint,
      ])
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t(
          'publishWithSimulatorPage.pilotSimulation.updatePilotErrorMessage'
        )
      )

      message.error({ content: status.error })
    },
  })
}

export default useUpdatePilotSimulation
