import { FC } from 'react'
import { Col, Row, Skeleton } from 'antd'
import { round } from 'lodash'
import { Space, Text, useIsThirdGen } from '@signifyd/components'
import { CHECKPOINT, PolicyReportResponse } from '@signifyd/http'
import { TIMEFRAME } from 'core/components/PolicyReporting/PolicyReporting.utils'
import { spacingLG } from '@signifyd/ant'
import LineGraph from '../LineGraph/LineGraph'
import { convertPolicyHistorytoChartData } from '../PolicyReports.utils'
import TextTooltip from '../TextTooltip/TextTooltip'
import styles from './HitReport.less'

interface HitReportProps {
  checkpoint: Exclude<CHECKPOINT, CHECKPOINT.LOGIN>
  data?: PolicyReportResponse
  isLoading: boolean
  timeframe: TIMEFRAME
  type: 'Count' | 'Gmv'
}

type HitsFields = 'hitsByCount' | 'hitsByGmv'
type PercentageOfTotalFields = 'percentageOfTotalCount' | 'percentageOfTotalGmv'
type PercentageOfTotalAffectedByPoliciesFields =
  | 'percentageOfTotalAffectedByPolicies'
  | 'percentageOfTotalGmvAffectedByPolicies'

const formatPercentage = (value: number): string => {
  if (value === 0) {
    return `${value}%`
  }

  if (value < 1) {
    return `${value.toLocaleString('en', {
      minimumFractionDigits: 2,
    })}%`
  }

  return `${round(value)}%`
}

const HitReport: FC<HitReportProps> = ({
  checkpoint,
  data,
  isLoading,
  timeframe,
  type,
}) => {
  const isThirdGen = useIsThirdGen()

  if (!data || isLoading) {
    return (
      <Skeleton
        className={styles.container}
        data-test-id={`hitReport${type}Loading`}
        active
        loading
        paragraph={{
          rows: 8,
          width: [...Array(8).fill('90%')],
        }}
      />
    )
  }

  const chartData = convertPolicyHistorytoChartData(data, type)

  const hits: HitsFields = `hitsBy${type}`
  const percentageOfTotal: PercentageOfTotalFields = `percentageOfTotal${type}`
  const percentageOfTotalAffectedByPolicies: PercentageOfTotalAffectedByPoliciesFields = `percentageOfTotal${
    type === 'Count' ? '' : 'Gmv'
  }AffectedByPolicies`

  return (
    <div className={styles.container}>
      <TextTooltip block checkpoint={checkpoint} field={hits} size="sm" />
      <Text className={styles.fontNumberValue} data-test-id={hits} size="lg">
        {type === 'Gmv' ? '$' : ''}
        {round(data[hits]).toLocaleString('en', {
          useGrouping: true,
        })}
      </Text>
      <Space size="sm" />
      <Row>
        <Col className={styles.paddingRight} span={12}>
          <TextTooltip
            block
            checkpoint={checkpoint}
            className={styles.textColorYonder}
            field={percentageOfTotal}
            size="xs"
          />
          <Text
            block
            className={styles.fontPercentageValue}
            data-test-id={percentageOfTotal}
            size="lg"
          >
            {formatPercentage(data[percentageOfTotal])}
          </Text>
        </Col>
        <Col className={styles.paddingRight} span={12}>
          <TextTooltip
            block
            checkpoint={checkpoint}
            className={styles.textColorYonder}
            field={percentageOfTotalAffectedByPolicies}
            size="xs"
          />
          <Text
            block
            className={styles.fontPercentageValue}
            data-test-id={percentageOfTotalAffectedByPolicies}
            size="lg"
          >
            {formatPercentage(data[percentageOfTotalAffectedByPolicies])}
          </Text>
        </Col>
      </Row>
      <Space size={isThirdGen ? spacingLG : 'sm'} />
      <div className={styles.chart}>
        <LineGraph data={chartData} timeframe={timeframe} type={`hit${type}`} />
      </div>
    </div>
  )
}

export default HitReport
