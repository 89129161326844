import moment, { Moment } from 'moment-timezone'
import { i18nInstance } from '@signifyd/components'
import { Text } from '@signifyd/components'
import styles from './PilotPreferenceModal.less'

const MAX_WEEK_TIMEFRAME = 2

export const getMinTimeframe = (): Moment =>
  moment().utc().add(1, 'day').endOf('day')
export const getMaxTimeframe = (startDate?: string): Moment => {
  if (startDate) {
    const editingEndDate = moment(startDate)
      .utc()
      .add(MAX_WEEK_TIMEFRAME, 'weeks')
      .endOf('day')

    return editingEndDate
  }

  return moment().utc().add(MAX_WEEK_TIMEFRAME, 'weeks')
}

export const isDateDisabled = (
  inputDate: Moment,
  endDate?: string
): boolean => {
  if (!endDate || !inputDate) {
    return false
  }

  const currentEndDate = moment(endDate).utc()

  return currentEndDate.isSame(inputDate, 'day')
}

const translationPrefix =
  'publishWithSimulatorPage.pilotSimulation.preferencesModal.datePicker'

const { t } = i18nInstance
export interface ValueDate<DateType> {
  label: React.ReactNode
  value: DateType | (() => DateType)
}

export const getDatePresets = (): Array<ValueDate<Moment>> => [
  {
    label: (
      <Text className={styles.datePreset}>
        {t(`${translationPrefix}.twentyFourHours`)}
      </Text>
    ),
    value: moment().utc().add(1, 'day').endOf('day'),
  },
  {
    label: (
      <Text className={styles.datePreset}>
        {t(`${translationPrefix}.fortyEightHours`)}
      </Text>
    ),
    value: moment().utc().add(2, 'day').endOf('day'),
  },
  {
    label: (
      <Text className={styles.datePreset}>
        {t(`${translationPrefix}.oneWeek`)}
      </Text>
    ),
    value: moment().utc().add(1, 'week').endOf('day'),
  },
  {
    label: (
      <Text className={styles.datePreset}>
        {t(`${translationPrefix}.twoWeeks`)}
      </Text>
    ),
    value: moment().utc().add(2, 'weeks').endOf('day'),
  },
]
